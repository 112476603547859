import { _log } from './../log/logger';
import { backupCookies } from './../variables';
import { isOnCookiesBlacklist } from './checks';

// Boolean to enable/disable cookie monkey patching
var _monkeyCookies = true;
export function enableMonkeyCookies() {
    _monkeyCookies = true;
}
export function disableMonkeyCookies() {
    _monkeyCookies = false;
}

// Monkey patch the 'cookie' document property to block unwanted cookies
export const originalCookieDescriptor = (
    Object.getOwnPropertyDescriptor(document, 'cookie') ||
    Object.getOwnPropertyDescriptor(Document.prototype, 'cookie') ||
    Object.getOwnPropertyDescriptor(HTMLDocument.prototype, 'cookie')
);

export function monkeyPatchCookies() {

    Object.defineProperty(document, 'cookie', {
        configurable: true,
        get: function () {
            return originalCookieDescriptor.get.apply(document);
        },
        set: function () {
            _log("Cookie set:", arguments);
            let cookie_name = arguments[0].split('=')[0];
            if (_monkeyCookies && isOnCookiesBlacklist(cookie_name)) {
                _log("Cookie blocked:", cookie_name);
                backupCookies.blacklisted.push({
                    name: cookie_name,
                    args: arguments,
                });
            } else {
                originalCookieDescriptor.set.apply(document, arguments);
            }
        }
    });
}

