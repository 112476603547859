import { el } from 'redom';
import { translate } from './../i18n/i18n';

export function build_banner() {
  const config = window.Lawwwing.config;

  // Fetch consent bar text an set as innerhtml string
  // Done this way to avoid issues having a body inside
  const consent_bar_text = el('div.lawwwing-banner-text');
  consent_bar_text.innerHTML = translate(config.locales, 'cookie_bar_main_text');

  let powered_by = "";
  if ( config.features.show_powered_by_banner === true ) {
    powered_by = el('div.lawwwing-powered-wrapper',
      el('a.lawwwing-powered-link',
        el('span', 'Powered by'),
        el('img',
          {
            src: 'https://cdn.lawwwing.com/static/assets/img/logos/horizontal/logo_color1.webp',
            alt: 'Lawwwing logo',
            height: 18,
          }
        ),
        { href: "https://lawwwing.com", target: '_blank'}),
    );
  }

  // Build consent bar
  return el('div#lawwwing-consent-banner.lawwwing-banner-hidden',
    el('div.lawwwing-banner-wrapper',
      consent_bar_text,
      el('div.lawwwing-banner-buttons-wrapper',
        el('div.lawwwing-banner-buttons',
          el('button.ibamu-banner-edit', translate(config.locales, 'cookie_bar_link_settings')),
          el('button.ibamu-consent-accept', translate(config.locales, 'cookie_bar_btn_accept')),
          el('button.ibamu-consent-reject', translate(config.locales, 'cookie_bar_btn_reject'))
        ),
        powered_by,
      )
    )
  );
}
