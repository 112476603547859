import { el } from 'redom';

import { _log } from './../log/logger';
import { translate } from './../i18n/i18n';


// Private function to return a list of document available languages based
// on api response
function _get_document_available_languages(category) {
  const config = window.Lawwwing.config;
  const documents = config.documents;
  let languages = [];
  for (const [key, value] of Object.entries(documents[category])) {
    if ( (value) && (value !== "") ) {
      languages.push(key);
    }
  }
  return languages;
}

// Private function for getting document by language and handling the default
// if no document is found for that language
function _get_document_field_by_language(object, language, key) {
  // Get language configuration from api config
  const default_language = window.Lawwwing.config.language.default;

  // Check if object has defined language and return key. If not use default language
  if (Object.prototype.hasOwnProperty.call(object, language) && object[language]) {
    return (Object.prototype.hasOwnProperty.call(object[language], key)) ? object[language][key] : '[No translation]';
  } else {
    return (Object.prototype.hasOwnProperty.call(object[default_language], key)) ? object[default_language][key] : '[No translation]';
  }
}

// Build document links on footer
export function build_documents_footer() {
  const links = [];
  const config = window.Lawwwing.config;
  const language = window.Lawwwing.language;

  // return if footer is not enabled
  if (config.features.autofooter === false) {
    _log("Footer disabled 'features.autofooter === false'");
    return;
  }

  const documents = config.documents;
  Object.keys(documents).forEach(key => {
    links.push(
      el(
        'div.lawwwing-document-open' + '.' + key,
        _get_document_field_by_language(documents[key], language, 'title')
      )
    );
  });

  // Append to document footer link to re-open cookie configuration
  links.push(
    el(
      'div.lawwwing-consent-edit',
      translate(config.locales, 'cookie_bar_link_settings')
    )
  );

  return el('div#lawwwing-footer',
    el('div.lawwwing-footer-wrapper', links)
  );
}

// Function called when click on "ibamu-document-open" elements.
// Creates a modal with the document iframe on it.
export function build_document_modal(category) {
  const language = window.Lawwwing.language;
  const config = window.Lawwwing.config;
  const documents = config.documents;
  const document_url = _get_document_field_by_language(documents[category], language, 'url');
  const language_desc = {
    'en': 'English',
    'es': 'Español',
    'ca': 'Català'
  };

  // Build a list of all language options available
  let language_options = [];
  for (const key of _get_document_available_languages(category)) {
    language_options.push(
      el('option', language_desc[key], { value: key, selected: ( key === language ) ? true : false }),
    );
  }

  return el('div#ibamu-document-modal' + '.' + category,
    el('div.ibamu-document-content',
      el('div.ibamu-document-header',
        el('select#lawwwing-document-languages', language_options),
        el('button.ibamu-document-close', translate(config.locales, 'document_modal_close_text'))
      ),
      el('iframe.ibamu-document-frame', '', {
        src: document_url,
        frameborder: '0',
        scrolling: 'auto'
      })
    )
  );
}

export function update_document_modal(element, category, language) {
  const document_url = _get_document_field_by_language(
    window.Lawwwing.config.documents[category], language, 'url'
  );
  try {
    element.getElementsByTagName('iframe')[0].src = document_url;
  } catch (error) {
    _log(error);
  }
}
