import { startObserver } from './block/observer';
import { monkeyPatchScripts } from './block/monkey_scripts';
import { monkeyPatchCookies } from './block/monkey_cookies';
import { LawwwingBase } from './base';

function _params_getter() {
  return new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
}

function _index() {
  let params = _params_getter();
  if (params.lawwwing_audit_job) {
    console.log("[lawwwing] Audit in process. Disabling");
    return;
  }

  if (params.gtm_debug) {
    console.log("[lawwwing] GTM Debugging detected. Disabling");
    return;
  }

  // Start observer
  startObserver();
  // Monkey patch scripts
  monkeyPatchScripts();
  // Monkey patch cookies
  monkeyPatchCookies();

  // Initialize widget
  window.Lawwwing = new LawwwingBase();
  window.Lawwwing.initialize();

  // Backward compatibility for Ibamu
  window.Ibamu = window.Lawwwing || {};
}

_index();
