import { el } from 'redom';
import Utils from './../utils';

export function build_style() {
  const theme = window.Lawwwing.config.theme;

  // compute footer position based on height of body and height of window
  // si la altura del body es mayor a la de la ventana, static
  // sino position absolute bottom 0
  let document_footer_position = 'static';
  let body_height = document.body.offsetHeight;
  let window_height = document.documentElement.clientHeight;
  if ( body_height < window_height * 0.9 ) {
    document_footer_position = 'fixed';
  } else {
    document_footer_position = 'static';
  }

  // theme variables
  const cookie_bar_background_color = theme.cookie_bar_background_color;
  const cookie_bar_text_color = theme.cookie_bar_text_color;
  const cookie_bar_text_color_lighter = Utils.lighten_darker(cookie_bar_text_color, 1000);
  const cookie_bar_button_background_color = theme.cookie_bar_button_background_color;
  const cookie_bar_button_text_color = theme.cookie_bar_button_text_color;
  const document_footer_background_color = theme.document_footer_background_color;
  const document_footer_text_color = theme.document_footer_text_color;
  const text_font_size = theme.text_font_size;
  const button_border_radius = theme.button_border_radius;

  // computed variables
  const text_font_size_subtitle = text_font_size + 4;
  const cookie_bar_button_color_darker = Utils.lighten_darker(cookie_bar_button_background_color, -15);

  return el('style', `
    /* Lawwwing consent banner */
    #lawwwing-consent-banner,
    #lawwwing-consent-banner * {
      box-sizing: border-box;
      font-size: ${text_font_size}px;
    }

    #lawwwing-consent-banner {
      background-color: ${cookie_bar_background_color};
      color: ${cookie_bar_text_color};
      padding: 1rem;
      font-family: sans-serif;
      line-height: 150%;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2147483647;
      transform: translateY(0);
      transition: transform .6s ease-in-out;
      transition-delay: .1s;
    }

    #lawwwing-consent-banner.lawwwing-banner-hidden {
      transform: translateY(100%);
      display: block;
    }

    #lawwwing-consent-banner .lawwwing-banner-wrapper {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      max-width: 1280px;
      margin: 0 auto;
    }

    #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-text {
      white-space: pre-line;
      background-color: ${cookie_bar_background_color};
      color: ${cookie_bar_text_color};
      text-align: justify;
      padding: 0rem 32px 0rem 16px;
      overflow-y: hidden;
      max-height: 50vh;
    }

    #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-text > a,
    #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-text > span {
      color: ${cookie_bar_text_color};
      text-decoration: underline;
      cursor: pointer;
    }

    #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper .lawwwing-banner-buttons {
      align-self: center;
      white-space: nowrap;
      color: #FFF;
    }

    #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper .lawwwing-banner-buttons button {
      line-height: 150%;
      font-size: ${text_font_size}px !important;
      border: none;
      border-radius: ${button_border_radius}px;
      padding: 10px 10px !important;
      color: ${cookie_bar_button_text_color};
      background-color:${cookie_bar_button_background_color};
      margin-right: 15px !important;
      min-width: 180px;
      text-shadow: unset;
    }

    #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper .lawwwing-banner-buttons button:hover {
      background-color: ${cookie_bar_button_color_darker};
      color: ${cookie_bar_text_color_lighter};
      cursor: pointer;
    }

    #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper .lawwwing-banner-buttons button.ibamu-consent-reject {
      display: none;
    }

    #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper .lawwwing-banner-buttons button.ibamu-banner-edit {
      background-color: #FFF;
      border: 1px solid ${cookie_bar_button_background_color};
      color: #111;
    }

    #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper .lawwwing-powered-wrapper {
      align-self: end;
    }

    #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper .lawwwing-powered-wrapper a {
      text-decoration: none;
    }

    #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper .lawwwing-powered-wrapper a > span {
      font-size: 10px;
      margin-right: 0.3rem;
    }

    #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper .lawwwing-powered-wrapper a > img {
      vertical-align: middle;
      filter: gray;
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
    }

    @media only screen and (max-width: 992px) {
      #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-text {
        max-width: 65%;
        padding-right: 10px;
      }

      #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper {
        width: 100%;
        padding: 0px 16px;
      }

      #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper .lawwwing-banner-buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper .lawwwing-banner-buttons button {
        margin: 0;
      }

      #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper .lawwwing-banner-buttons button.ibamu-banner-edit {
        order: 1;
        margin-top: 8px;
      }
    }

    @media only screen and (max-width: 600px) {
      #lawwwing-consent-banner .lawwwing-banner-wrapper {
        flex-wrap: wrap;
      }

      #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-text {
        padding: 0;
        max-width: unset;
        overflow-y: scroll;
        width: 100%;
      }

      #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper {
        width: 100%;
        margin-top: 20px;
        padding: 0;
      }

      #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper .lawwwing-banner-buttons {
        width: 100%;
      }

      #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper .lawwwing-banner-buttons button {
        min-width: unset;
      }

      #lawwwing-consent-banner .lawwwing-banner-wrapper .lawwwing-banner-buttons-wrapper .lawwwing-powered-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        align-self: stretch;
        margin-top: 2rem;
      }
    }

    /* Lawwwing preferences modal */
    #lawwwing-consent-modal,
    #lawwwing-consent-modal * {
      font-size: ${text_font_size}px;
    }

    #lawwwing-consent-modal *:after, #lawwwing-consent-modal *:before {
      content: unset;
    }

    #lawwwing-consent-modal {
      display: none;
      font-size: ${text_font_size}px;
      line-height: 150%;
      color: #666;
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      font-family: sans-serif;
      font-size:${text_font_size}px;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 2147483647;
      align-items: center;
      justify-content: center;
    }

    @media only screen and (max-width: 600px) {
      #lawwwing-consent-modal {
        height: 100%
      }
    }

    #lawwwing-consent-modal.lawwwing-modal-visible {
      display: flex
    }

    #lawwwing-consent-modal .ibamu_consent_content {
      max-width: 60vw;
      max-height: 90vh;
      overflow-Y: auto;
      background-color: #FFFFFF;
    }

    @media only screen and (max-width: 600px) {
      #lawwwing-consent-modal .ibamu_consent_content {
        max-width: 100vw !important;
        max-height: 100vh !important;
        height: 100%;
      }
    }

    @media only screen and (min-width: 600px) and (max-width: 1200px) {
      #lawwwing-consent-modal .ibamu_consent_content {
        max-width: 80vw;
        max-height: 90vh;
      }
    }

    #lawwwing-consent-modal .lawwwing-consent-header {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      vertical-align: middle;
      border-bottom: 1px solid #ddd;
    }

    #lawwwing-consent-modal .lawwwing-consent-header-title {
      font-size: 21px;
      font-weight: 600;
      padding: 10px 16px;
      color: #333;
      margin: 0;
    }

    .lawwwing-consent-header > button {
      color: ${cookie_bar_button_background_color};
      border: 1px solid ${cookie_bar_button_background_color};
      padding: 10px 32px;
      border-radius: 500px;
      background-color: white;
      cursor: pointer;
      font-weight: bold;
    }

    .lawwwing-consent-header > button:hover {
      color: white;
      border: 1px solid ${cookie_bar_button_background_color};
      padding: 10px 32px;
      border-radius: 500px;
      background-color: ${cookie_bar_button_background_color};
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_heading {
      border-bottom: 1px solid #D8D8D8;
      padding: 10px 32px 10px;
      background-color: #FFFFFF;
      position: relative;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_heading p {
      white-space: pre-line;
    }

    #lawwwing-consent-modal .ibamu_consent_content > .ibamu_consent_content_heading .ibamu_consent_modal_text a,
    #lawwwing-consent-modal .ibamu_consent_content > .ibamu_consent_content_heading .ibamu_consent_modal_text span {
      text-decoration: underline;
      cursor: pointer;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_heading body {
      margin-top: 8px;
      background-color: rgba(0, 0, 0, 0);
    }

    #lawwwing-consent-modal .ibamu_consent_content>.lawwwing-consent-header .ibamu_consent_cheading_close {
      font-size: 26px;
      font-weight: 600;
      color: #888;
      cursor: pointer;
      padding: 9px 16px;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body {
      background-color: #FFF;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tabgroup {
      margin: 0;
      border-bottom: 1px solid #D8D8D8;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tabgroup .ibamu_consent_tab-head::before {
      position: absolute;
      left: 35px;
      font-size: 1.4em;
      font-weight: 600;
      color: #E56385;
      content: "\\00D7" !important;
      display: inline-block;
      margin-right: 20px;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tabgroup.ibamu_checked .ibamu_consent_tab-head::before {
      font-size: 1em;
      content: "\\2714" !important;
      color: #28A834
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tabgroup .ibamu_consent_tab-head .ibamu_consent_tab-head_icon-wedge {
      transition: transform .3s ease-out;
      transform-origin: 16px 6px 0;
      position: absolute;
      right: 25px;
      top: 50%;
      transform: rotate(0deg);
      transform: translateY(-50%)
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tabgroup .ibamu_consent_tab-head .ibamu_consent_tab-head_icon-wedge>svg {
      pointer-events: none;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tabgroup.ibamu_consent_tabgroup-open .ibamu_consent_tab-head .ibamu_consent_tab-head_icon-wedge {
      transform: rotate(-180deg)
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-head {
      color: #333;
      padding: 17px 35px 17px 56px;
      margin: 0
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-content {
      padding: 25px 35px;
      margin: 0
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-head {
      transition: background-color .5s ease-out
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-head:hover {
      background-color: #F9F9F9
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-head {
      font-weight: 600;
      cursor: pointer;
      position: relative;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tabgroup .ibamu_consent_tab-content {
      display: none;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tabgroup.ibamu_consent_tabgroup-open .ibamu_consent_tab-head {
      background-color: #F9F9F9;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tabgroup.ibamu_consent_tabgroup-open .ibamu_consent_tab-content {
      display: flex;
    }

    @media only screen and (max-width: 600px) {
      #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tabgroup.ibamu_consent_tabgroup-open .ibamu_consent_tab-content {
        flex-direction: column;
      }
    }

    @media only screen and (max-width: 600px) {
      #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-content .ibamu_consent_tab-content_left {
        margin-bottom: 20px;
      }
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-content .ibamu_consent_tab-content_left .ibamu_consent_switch-component {
      display: flex;
      margin-right: 35px;
      align-items: center;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-content .ibamu_consent_tab-content_left .ibamu_consent_switch-component>div {
      font-weight: 600;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-content .ibamu_consent_tab-content_left .ibamu_consent_switch-group {
      width: 40px;
      height: 20px;
      margin: 0 10px;
      position: relative;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-content .ibamu_consent_tab-content_left .ibamu_consent_switch {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      width: 40px;
      height: 20px;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-content .ibamu_consent_tab-content_left .ibamu_consent_switch input {
      display: none;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-content .ibamu_consent_tab-content_left .ibamu_consent_switch .ibamu_consent_switch_slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      border-radius: 10px;
      -webkit-transition: .4s;
      transition: .4s;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-content .ibamu_consent_tab-content_left .ibamu_consent_switch .ibamu_consent_switch_slider:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      border-radius: 50%;
      -webkit-transition: .4s;
      transition: .4s;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-content .ibamu_consent_tab-content_left .ibamu_consent_switch input:checked+.ibamu_consent_switch_slider {
      background-color: #28A834;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-content .ibamu_consent_tab-content_left .ibamu_consent_switch input:focus+.ibamu_consent_switch_slider {
      box-shadow: 0 0 1px #28A834;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-content .ibamu_consent_tab-content_left .ibamu_consent_switch input:checked+.ibamu_consent_switch_slider:before {
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-content p.lawwwing_category_name {
      font-size: ${text_font_size_subtitle}px;
      margin-bottom: 10px;
      line-height: 1;
      color: #333;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-content p {
      color: #666;
      margin-bottom: 0
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-content .ibamu_consent_list:not(:empty) {
      margin-top: 10px;
    }

    #lawwwing-consent-modal .ibamu_consent_content>.ibamu_consent_content_body .ibamu_consent_tab-content .ibamu_consent_list .ibamu_consent_list_title {
      color: #333;
      font-weight: 600;
    }

    #lawwwing-consent-modal .ibamu_consent_list ul {
      padding: 0px 0px 0px 16px;
      margin: 5px 0px 0px 0px;
    }

    #lawwwing-consent-modal .ibamu_consent_list li {
      font-weight: bold;
      margin: 5px 0px 0px 0px;
    }

    #lawwwing-consent-modal .ibamu_consent_cookielist li {
      font-weight: normal;
      margin: 0;
    }

    #lawwwing-consent-modal ul.ibamu_consent_cookielist {
      padding: 0px 0px 0px 24px;
      margin: 0;
    }

    #lawwwing-consent-modal .lawwwing-consent-footer-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
    }

    #lawwwing-consent-modal .lawwwing-consent-footer-wrapper .lawwwing-powered-wrapper {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 20px;
    }

    #lawwwing-consent-modal .lawwwing-consent-footer-wrapper .lawwwing-powered-link {
      text-decoration: none;
    }

    #lawwwing-consent-modal .lawwwing-consent-footer-wrapper .lawwwing-powered-link > span {
      font-size: 10px;
      color: gray;
      margin-right: 0.3rem;
      vertical-align: middle;
    }

    #lawwwing-consent-modal .lawwwing-consent-footer-wrapper .lawwwing-powered-link > img {
      height: 18px;
      width: 100px;
      vertical-align: middle;
      filter: gray; /* IE6-9 */
      -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
      filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    }

    @media only screen and (max-width: 850px) {
      #lawwwing-consent-modal .lawwwing-consent-footer-wrapper .lawwwing-powered-wrapper {
        width: 100%;
        justify-content: center;
        order: 2;
      }

      #lawwwing-consent-modal .ibamu_consent_footer {
        order: 1;
      }
    }

    #lawwwing-consent-modal .ibamu_consent_footer {
      padding: 35px;
      background-color: #FFFFFF;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    #lawwwing-consent-modal .ibamu_consent_footer button {
      line-height: 150%;
      font-size: ${text_font_size}px;
      transition: background-color .5s ease-out;
      background-color: ${cookie_bar_button_background_color};
      color: ${cookie_bar_button_text_color};
      border: none;
      border-radius: ${button_border_radius}px;
      padding: 13px !important;
      min-width: 110px !important;
      cursor: pointer;
      margin-right: 10px;
    }

    #lawwwing-consent-modal .ibamu_consent_footer button:hover {
      background-color: ${cookie_bar_button_color_darker};
    }

    #lawwwing-consent-modal .ibamu_consent_footer #ibamu_consent_footer_consent-modal-submit {
      background-color: #FFF;
      border: 1px solid ${cookie_bar_button_background_color};
      color: #111;
    }

    @media only screen and (max-width: 850px) {
      #lawwwing-consent-modal .ibamu_consent_footer {
        flex-wrap: wrap;
        width: 100%;
      }
    }

    @media only screen and (max-width: 850px) {
      #lawwwing-consent-modal .ibamu_consent_footer button {
        width: 100%;
        margin-top: 10px;
        margin-right: 0px;
      }
    }

    /* Lawwwing footer */
    #lawwwing-footer,
    #lawwwing-footer * {
      font-size: ${text_font_size}px;
    }

    #lawwwing-footer {
      position: ${document_footer_position};
      bottom: 0;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #lawwwing-footer .lawwwing-footer-wrapper {
      background-color: ${document_footer_background_color};
      color: ${document_footer_text_color};
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: center;
      width: 100%;
      margin: 0 auto;
      padding: 0.5rem 0rem;
    }

    #lawwwing-footer div.ibamu-document-open,
    #lawwwing-footer div.ibamu-consent-edit,
    #lawwwing-footer div.lawwwing-document-open,
    #lawwwing-footer div.lawwwing-consent-edit {
      color: ${document_footer_text_color};
      margin-right: 1rem;
      text-decoration: none;
    }

    #lawwwing-footer div.ibamu-document-open:hover,
    #lawwwing-footer div.ibamu-consent-edit:hover,
    #lawwwing-footer div.lawwwing-document-open:hover,
    #lawwwing-footer div.lawwwing-consent-edit:hover {
      font-weight: bolder;
      cursor: pointer
    }

    @media only screen and (max-width: 600px) {
      #lawwwing-footer .lawwwing-footer-wrapper {
        flex-direction: column;
      }

      #lawwwing-footer div.ibamu-document-open,
      #lawwwing-footer div.ibamu-consent-edit,
      #lawwwing-footer div.lawwwing-document-open,
      #lawwwing-footer div.lawwwing-consent-edit {
        text-align: center;
        margin: 0.5rem 0rem;
      }
    }

    /* Lawwwing document modal */
    @media only screen and (max-width: 600px) {
      #ibamu-document-modal .ibamu-document-content {
        height: 100% !important;
        width: 100% !important;
      }
    }

    #ibamu-document-modal {
      display: none;
      font-size: ${text_font_size}px;
      line-height: 150%;
      color: #666;
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      font-family: inherit;
      font-size: ${text_font_size}px;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 2147483647;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    #ibamu-document-modal {
      -webkit-user-select: none;
      -webkit-touch-callout: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    #ibamu-document-modal *:after, #ibamu-document-modal *:before {
      content: unset;
    }

    #ibamu-document-modal.lawwwing-modal-visible {
      display: flex;
    }

    @media only screen and (max-width: 800px) {
      #ibamu-document-modal .ibamu-document-close {
        top: 0px;
        right: 0px;
      }
    }

    #ibamu-document-modal .ibamu-document-content {
      display: flex;
      flex-direction: column;
      max-width: 75rem;
      width: 90%;
      height: 90%;
      border: 1px solid rgba(0, 0, 0, 0.3);
      box-shadow: rgb(0 0 0 / 30%) 0px 3px 7px;
      background-clip: padding-box;
      padding: 0px 15px 15px;
      background-color: white;
    }

    #ibamu-document-modal .ibamu-document-frame {
      width: 100%;
      height: 100%;
    }

    #ibamu-document-modal .ibamu-document-header {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      border-bottom: 1px solid #ddd;
    }

    #ibamu-document-modal .ibamu-document-header>button {
      color: ${cookie_bar_button_background_color};
      border: 1px solid ${cookie_bar_button_background_color};
      padding: 10px 32px;
      border-radius: ${button_border_radius}px;
      background-color: white;
      cursor: pointer;
      font-weight: bold;
      text-shadow: unset;
      width: unset;
    }

    #ibamu-document-modal .ibamu-document-header>button:hover {
      color: white;
      background-color: ${cookie_bar_button_background_color};
    }

    select#lawwwing-document-languages {
      padding: 10px 20px;
      color: ${cookie_bar_text_color};
      border-color: ${cookie_bar_text_color};
      background-color: white;
      cursor: pointer;
      width: unset;
      background-image: unset;
      background-size: unset;
      background-repeat: unset;
      background-position-x: unset;
      -webkit-appearance: unset;
    }
    `
  );
}
