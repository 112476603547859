import { _log } from './../log/logger';
import { enableMonkeyCookies, disableMonkeyCookies } from './monkey_cookies';

function removeCookie(cookie) {
  _log('Clean:', cookie);
  disableMonkeyCookies();
  document.cookie = `${cookie.name}=; expires=Thu, 01 Jan 1980 00:00:00 UTC; domain=${cookie.domain}; path=/;`;
  enableMonkeyCookies();
}

export function cleanUnwantedCookies() {
  _log('Cleaning unwanted cookies');
  let config = window.Lawwwing.config;

  let setted_cookies = [];
  document.cookie.split(';').map(
    function(cookie) {
      return setted_cookies.push(cookie.split('=')[0].replace(/(^\s*)|(\s*&)/, ''));
    }
  );

  // iterate over all configured services
  for (const service_key in config.services) {
    let service = config.services[service_key];
    // Remove cookies if they are not wanted by user
    if (!config.categories[service.category].wanted) {
      for (const i in service.cookies) {
        if ( setted_cookies.includes(service.cookies[i].name) ) {
          removeCookie(service.cookies[i]);
        }
      }
    }
  }
}
