import { originalCookieDescriptor } from './monkey_cookies';
import { updateBlacklists } from './blacklists';
import { _log } from './../log/logger';
import { unblock } from './unblock';
import Utils from './../utils';
import { backupCookies } from './../variables';

export function flush () {
  // Function called when user accepts/declines options

  // Update blacklist with config setted by user
  updateBlacklists();

  // Flush wanted cookies
  const wanted_cookies = Utils.get_wanted_cookies();
  _log('Flushing wanted cookies:', wanted_cookies);
  backupCookies.blacklisted.forEach(blocked_cookie => {
    wanted_cookies.forEach(wanted_cookie => {
      if (wanted_cookie.name === blocked_cookie.name) {

        // Remove cookie from blacklist
        const index = backupCookies.blacklisted.indexOf(wanted_cookie.name);
        if (index > -1) {
          backupCookies.blacklisted.splice(index, 1);
        }

        _log('Cookie flush:', blocked_cookie);
        originalCookieDescriptor.set.apply(document, blocked_cookie.args);
      }
    });
  });

  // Unblock scripts
  // Fixme: we're assuming that more than one category wanted means
  // consent to all scripts
  if (Utils.get_wanted_categories().length > 1 ) {
    unblock();
  }
}
