import { willBeUnblocked } from './checks';
import { _log, _log_err } from './../log/logger';
import { observer } from './observer';
import { patterns, backupScripts, TYPE_ATTRIBUTE } from './../variables';

// Unblocks all (or a selection of) blacklisted scripts.
export const unblock = function(...scriptUrlsOrRegexes) {
    if(scriptUrlsOrRegexes.length < 1) {
        patterns.blacklist = [];
    } else {
        if(patterns.blacklist) {
            patterns.blacklist = patterns.blacklist.filter(pattern => (
                scriptUrlsOrRegexes.every(urlOrRegexp => {
                    if(typeof urlOrRegexp === 'string')
                        return !pattern.test(urlOrRegexp);
                    else if(urlOrRegexp instanceof RegExp)
                        return pattern.toString() !== urlOrRegexp.toString();
                })
            ));
        }
    }

    // Parse existing script tags with a marked type
    const tags = document.querySelectorAll(`script[type="${TYPE_ATTRIBUTE}"]`);
    for(let i = 0; i < tags.length; i++) {
        const script = tags[i];
        if(willBeUnblocked(script)) {
            backupScripts.blacklisted.push([script, 'application/javascript']);
            script.parentElement.removeChild(script);
        }
    }

    // Exclude 'whitelisted' scripts from the blacklist and append them to <head>
    let indexOffset = 0;
    [...backupScripts.blacklisted].forEach(([script, type], index) => {
        if(willBeUnblocked(script)) {
            _log("Unblocking", script);
            const scriptNode = document.createElement('script');
            for(let i = 0; i < script.attributes.length; i++) {
                let attribute = script.attributes[i];
                if(attribute.name !== 'src' && attribute.name !== 'type') {
                    try {
                        scriptNode.setAttribute(attribute.name, script.attributes[i].value);
                    } catch(error) {
                        _log_err(error);
                    }
                }
            }
            scriptNode.setAttribute('src', script.src);
            scriptNode.setAttribute('type', type || 'application/javascript');
            document.head.appendChild(scriptNode);
            backupScripts.blacklisted.splice(index - indexOffset, 1);
            indexOffset++;
        }
    });

    // Disconnect the observer if the blacklist is empty for performance reasons
    if(patterns.blacklist && patterns.blacklist.length < 1) {
        observer.disconnect();
    }
};
