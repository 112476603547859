import { blacklistCookies } from '../variables';

export function updateBlacklists() {
  // Helper function to update global variable `blacklistCookies` with new
  // cookies fetched from api configuration.
  let config = window.Lawwwing.config;
  let services = {};

  const cookie_services = config.services;
  const cookie_categories = config.categories;

  // Populate services variable with services not wanted and not needed
  for (let service in cookie_services) {
    if (cookie_categories[cookie_services[service].category].needed === false) {
      if (cookie_categories[cookie_services[service].category].wanted === false) {
        services[service] = cookie_services[service];
      }
    }
  }

  // Empty blacklist cookie user before updating again
  blacklistCookies.user = [];
  // Add to blacklist all services cookies names
  for (const service in services) {
    services[service].cookies.forEach(
      cookie => {
        blacklistCookies.user.push(new RegExp(cookie.name));
      }
    );
  }
}
