export const LOGGING_COOKIE = 'lawwwing-logging';
export const STORAGE_COOKIE = 'lawwwing-cookie-consent';
export const TYPE_ATTRIBUTE = 'lawwwing/blocked';

// List of script patters to blacklist
export var patterns = {
    blacklist: [
        new RegExp("googletagmanager.com"),
        new RegExp("google-analytics.com"),
        new RegExp("youtube.com"),
        new RegExp("youtube-nocookie.com"),
        new RegExp("googleadservices.com"),
        new RegExp("googlesyndication.com"),
        new RegExp("doubleclick.net"),
        new RegExp("facebook.*"),
        new RegExp("linkedin.com"),
        new RegExp("twitter.com"),
        new RegExp("addthis.com"),
        new RegExp("bing.com"),
        new RegExp("sharethis.com"),
        new RegExp("yahoo.com"),
        new RegExp("addtoany.com"),
        new RegExp("dailymotion.com"),
        new RegExp("amazon-adsystem.com"),
        new RegExp("snap.licdn.com"),
    ]
};

// Backup list containing the original blacklisted script elements
export const backupScripts = {
    blacklisted: []
};

// List of blacklisted cookies
export const blacklistCookies = {
    common: [
        // new RegExp("_ga"),
    ],
    user: [],
};

// Backup list containing original blacklisted cookies
export const backupCookies = {
    blacklisted: [],
};
