import { _log, _log_warn } from './log/logger';
import { getPreferredLanguage } from './i18n/i18n';
import { readConfigFromStorage } from './storage';
import { updateBlacklists } from './block/blacklists';
import { flush } from './block/flush';
import { cleanUnwantedCookies } from './block/cleaner';
import { _is_true } from './utils';

import Interface from './ui/interface';

function _getAttrValue(attr) {
  let _value = document.currentScript.getAttribute(attr);
  if ( _value === "" || _value === null ) {
    _log_warn(`${attr} attribute not found or empty.`);
  }
  return _value;
}

function getPluginID() {
  return _getAttrValue("data-lwid");
}

function getPreviewMode() {
  return _is_true(_getAttrValue("data-lwpreview"));
}

function getDebugMode() {
  return _is_true(_getAttrValue("data-lwdebug"));
}

export class LawwwingBase {

  constructor() {
    // Base attributes
    this.id = getPluginID();
    this.preview = getPreviewMode();
    this.debug = getDebugMode();

    // Configuration attributes
    this.language = getPreferredLanguage();
    this.config = {};
    this.initialized = false;
    this.storage = false;  // config was found on storage
  }

  _getApiEndpoint() {
    let host = 'https://lawwwing.com';
    if (this.preview) {
      host = document.location.origin;
    }
    if (this.debug) {
      host = 'http://localhost:8000';
    }
    let url = `${host}/api/widgets/config/?wid=${this.id}`;
    return url;
  }

  async _fetchConfig() {
    let endpoint = this._getApiEndpoint();
    _log('GET:', endpoint);
    const response = await fetch(endpoint, {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': this.language,
        'lawwwing-widget-preview': `${this.preview}`,
        'lawwwing-widget-id': `${this.id}`
      },
      referrerPolicy: 'no-referrer-when-downgrade',
      redirect: 'follow'
    });

    if (!response.ok) {
      throw Error(response.status);
    }
    return await response.json();
  }

  async initialize() {
    if (!this.id) {
      _log_warn("ID not setted");
      return;
    }

    return this._fetchConfig()
      .then(
        response => {
          this.config = response;

          // read configuration from storage an override if something was changed
          readConfigFromStorage();

          // update cookie blacklist from configuration
          updateBlacklists();

          // clean cookies is some were setted
          cleanUnwantedCookies();

          // Flush buffers is config was readed from storage. So we already
          // have user preferences. Done this way since script blocking is done
          // before having api config response.
          if (this.storage) {
             flush();
          }

          // initialize and build interface
          const ui = new Interface();
          ui.build_interface(() => {
            ui.add_event_listeners();
          });

          // Set initialized to true
          this.initialized = true;
        }
      )
      .catch(
        err => _log_warn('Error fetching widget configuration:', err)
      );
  }

  async widget(params) {
    // Method for backward compatibility for < v5 plugin version.
    // It populates required class attributes and calls `initialize()` method
    this.id = params.wid;
    this.preview = params.preview;
    this.debug = params.debug;
    this.initialize();
  }

}
