import { LOGGING_COOKIE } from './../variables';
import { getCookieValue } from './../utils';

function lawwwing_log(level, ...args) {
  if ( window.Lawwwing && (window.Lawwwing.debug || getCookieValue(LOGGING_COOKIE))) {
    console.log(level, ...args);
  }
}

export function _log(...args) {
  lawwwing_log('[lawwwing-info]', ...args);
}

export function _log_warn(...args) {
  lawwwing_log('[lawwwing-warning]', ...args);
}

export function _log_err(...args) {
  lawwwing_log('[lawwwing-error]', ...args);
}
