import { _log } from './log/logger';
import { STORAGE_COOKIE } from './variables';

function _remove() {
  // Remove storage cookie and reload page
  _log(`Removing ${STORAGE_COOKIE} cookie`);
  document.cookie = `${STORAGE_COOKIE}=; expires=Thu, 01 Jan 1980 00:00:00 UTC; path=/;`;
  location.reload();
}

export function readConfigFromStorage() {

  document.cookie.split(';').filter((cookie) => {

    if (cookie.indexOf(STORAGE_COOKIE) >= 0) {
      const cookieData = JSON.parse(cookie.split('=')[1]);

      // We check cookie version. If older we need to renew cookie.
      if (typeof cookieData.version === 'undefined') {
        return _remove();
      } else {
        if (cookieData.version !== window.Lawwwing.config.version) {
          return _remove();
        }
      }

      // We check if cookie data categories also exist in user config
      for (const key in cookieData.categories) {
        // The cookie contains category not present in user config so we invalidate cookie
        if (typeof window.Lawwwing.config.categories[key] === 'undefined') {
          return _remove();
        }
      }

      // We check if cookie data services also exist in user config
      cookieData.services.forEach(function (service) {
        // The cookie contains service not present in user config so we invalidate cookie
        if (typeof window.Lawwwing.config.services[service] === 'undefined') {
          return _remove();
        }
      });

      // We integrate cookie data into the global config object
      for (const key in cookieData.categories) {
        window.Lawwwing.config.categories[key].checked = window.Lawwwing.config.categories[key].wanted = (cookieData.categories[key].wanted === true);
      }

      // If cookie was found set storage to true
      window.Lawwwing.storage = true;
      // Unset storage if preview to avoid closing the popup
      if (window.Lawwwing.preview === true) {
        window.Lawwwing.storage = false;
      }
    }
  });
}

