export function getPreferredLanguage() {
  // Return browser configured language with 'short' format
  let lang = window.navigator.languages ? window.navigator.languages[0] : null;
  lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;

  // Get lang from html label if found has preference over browser
  let html_lang = document.documentElement.lang;
  if ( html_lang ) {
    lang = html_lang;
  }

  let short = lang;
  if (short.indexOf('-') !== -1) {
    short = short.split('-')[0];
  }
  if (short.indexOf('_') !== -1) {
    short = short.split('_')[0];
  }
  return short;
}

export function translate(object, key) {
  // Browser language (defined on configuration.js)
  let language = window.Lawwwing.language;

  // Get default locale from api config
  const default_locale = window.Lawwwing.config.language.default;

  // Get from config which languages are available for that widget
  const available_locales = window.Lawwwing.config.language.available;

  // If browser language is not in available languages, set language the the default one
  if ( ! available_locales.includes(language) ) {
    language = default_locale;
  }

  // Check objects has language key and is not empty
  if (Object.prototype.hasOwnProperty.call(object, language) && object[language]) {
    const locale = object[language];
    // Check locale has key and is not empty
    if (Object.prototype.hasOwnProperty.call(locale, key) && locale[key]) {
      return locale[key];
    }
  }

  // If no locale found for this object return default string
  return '[Missing translation]';
}
