export function _is_true(value) {
  // Returns boolean true if value is true equivalent
  return value === true || value === "true" || value === "True";
}

export function getCookieValue(name) {
  // Returns cookie value by name if found
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export default class Utils {

  // util function to get wanted categories
  static get_wanted_categories() {
    const wanted_categories = [];
    for (const category in window.Lawwwing.config.categories) {
      if (window.Lawwwing.config.categories[category].wanted === true) {
        wanted_categories.push(category);
      }
    }
    return wanted_categories;
  }

  // util function to get wanted services
  static get_wanted_services() {
    const wanted_services = [];
    this.get_wanted_categories().forEach(category => {
      for (const service in window.Lawwwing.config.services) {
        if (window.Lawwwing.config.services[service].category === category) {
          wanted_services.push(window.Lawwwing.config.services[service]);
        }
      }
    });
    return wanted_services;
  }

  // get wanted cookies
  static get_wanted_cookies() {
    const wanted_cookies = [];
    this.get_wanted_services().forEach(service => {
      service.cookies.forEach(cookie => {
        wanted_cookies.push(cookie);
      });
    });
    return wanted_cookies;
  }

  static get_cookie_value(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  static ready(fn) {
    if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
      fn();
    } else {
      document.addEventListener('DOMContentLoaded', fn);
    }
  }

  static objectType(obj) {
    return Object.prototype.toString.call(obj).slice(8, -1);
  }

  static lighten_darker(col, amt) {

    let usePound = false;

    if (col[0] == '#') {
      col = col.slice(1);
      usePound = true;
    }

    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    let b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    let g = (num & 0x0000FF) + amt;

    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);

  }

  // Create an array of services from Lawwwing global object
  // Filter based on category or leave empty is all is wanted
  static listGlobalServices(category) {
    const categories = [];

    // Global config objectnot set
    if (typeof window.Lawwwing === 'undefined') return categories;

    // Category is not specified or opposite
    if (typeof category === 'undefined') {
      for (const key in window.Lawwwing.config.services) {
        categories.push(key);
      }
    } else {
      for (const key in window.Lawwwing.config.services) {
        if (window.Lawwwing.config.services[key].category === category) categories.push(key);
      }
    }

    return categories;
  }
}
