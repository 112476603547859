import { patterns, blacklistCookies, TYPE_ATTRIBUTE } from './../variables';

export const isOnScriptsBlacklist = (src, type) => (
    src &&
    (!type || type !== TYPE_ATTRIBUTE) &&
    (
        (!patterns.blacklist || patterns.blacklist.some(pattern => pattern.test(src)))
    )
);

export const willBeUnblocked = function(script) {
    const src = script.getAttribute('src');
    return (
        patterns.blacklist && patterns.blacklist.every(entry => !entry.test(src))
    );
};


export const isOnCookiesBlacklist = (name) => (
    name &&
    (
        (!blacklistCookies.common || blacklistCookies.common.some(pattern => pattern.test(name))) ||
        (!blacklistCookies.user || blacklistCookies.user.some(pattern => pattern.test(name)))
    )
);
