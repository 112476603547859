import { el, svg } from 'redom';
import { translate } from './../i18n/i18n';

export function build_modal() {
  const config = window.Lawwwing.config;

  // Cookie names list middleware
  const listCookies = function(category) {
    const list = [];
    let serviceCookies = [];

    for (const service in config.services) {
      (config.services[service].category === category) && list.push(config.services[service]);
    }

    if (list.length) {

      const listItems = [];

      for (const provider in list) {
        // fetch service cookies
        for (const cookie in list[provider].cookies) {
          serviceCookies.push(
            el('li', list[provider].cookies[cookie].name)
          );
        }
        listItems.push(
          el('li', list[provider].name), el('ul.ibamu_consent_cookielist', serviceCookies)
        );
        serviceCookies = [];
      }

      return [
          el('span.ibamu_consent_list_title', translate(config.locales, 'modal_affected_solutions')),
          el('ul', listItems)
      ];
    }
  };

  function cookie_modal_tab_groups() {
    const contentItems = [];

    for (const key in config.categories) {

      contentItems.push(
        el('dl.ibamu_consent_tabgroup' + '.' + key + ((config.categories[key].checked) ? '.ibamu_checked' : ''), { 'data-category': key },
          el('dt.ibamu_consent_tab-head', translate(config.categories[key].locales, 'name'),
            el('span.ibamu_consent_tab-head_icon-wedge',
              svg(
                'svg.icon-consent-tab',
                {
                  version: '1.2',
                  preserveAspectRatio: 'none',
                  viewBox: '0 0 24 24',
                  style: 'opacity: 1; mix-blend-mode: normal; fill: rgb(51, 51, 51); width: 32px; height: 32px;'
                },
                svg(
                  'path.icon-path-consent-tab',
                  {
                    d: 'M17.2,9.84c0-0.09-0.04-0.18-0.1-0.24l-0.52-0.52c-0.13-0.13-0.33-0.14-0.47-0.01c0,0-0.01,0.01-0.01,0.01  l-4.1,4.1l-4.09-4.1C7.78,8.94,7.57,8.94,7.44,9.06c0,0-0.01,0.01-0.01,0.01L6.91,9.6c-0.13,0.13-0.14,0.33-0.01,0.47  c0,0,0.01,0.01,0.01,0.01l4.85,4.85c0.13,0.13,0.33,0.14,0.47,0.01c0,0,0.01-0.01,0.01-0.01l4.85-4.85c0.06-0.06,0.1-0.15,0.1-0.24  l0,0H17.2z',
                    style: 'fill: rgb(51, 51, 51);'
                  }
                )
              )
            )
          ),
          el('dd.ibamu_consent_tab-content',
            el('div.ibamu_consent_tab-content_left',
              (!config.categories[key].needed) && el('div.ibamu_consent_switch-component', el('div.status-off', translate(config.locales, 'category_off')),
                el('div.ibamu_consent_switch-group',
                  el('label.ibamu_consent_switch',
                    el('input.category-onoff', { type: 'checkbox', 'data-category': key, checked: config.categories[key].checked }),
                    el('span.ibamu_consent_switch_slider')
                  )
                ),
                el('div.status-on', translate(config.locales, 'category_on')))
            ),
            el('div.right',
              el('p.lawwwing_category_name', translate(config.categories[key].locales, 'name')),
              el('p', translate(config.categories[key].locales, 'description')),
              el('div.ibamu_consent_list',
                listCookies(key)
              )
            )
          )
        )
      );
    }
    return contentItems;
  }

  // Render powered by if setted on config
  let powered_by = "";
  if ( config.features.show_powered_by ) {
    powered_by = el('div.lawwwing-powered-wrapper',
      el('a.lawwwing-powered-link',
        el('span', 'Powered by'),
        el('img',
          {
            src: 'https://cdn.lawwwing.com/static/assets/img/logos/horizontal/logo_color1.webp',
            alt: 'Lawwwing logo',
            height: 18,
          }
        ),
        { href: "https://lawwwing.com", target: '_blank'}),
    );
  }

  // Fetch cookie modal main text an set as innerhtml string
  // Done this way to avoid issues having a body inside
  const modal_main_text = el('p.ibamu_consent_modal_text');
  modal_main_text.innerHTML = translate(config.locales, 'modal_main_text');

  return el('div#lawwwing-consent-modal',
    el('div.ibamu_consent_content',

      el('div.lawwwing-consent-header',
        el('p.lawwwing-consent-header-title',
          translate(config.locales, 'modal_main_title')
        ),
        el('div.ibamu_consent_cheading_close', 'x'),
      ),

      el('div.ibamu_consent_content_heading', modal_main_text),
      el('div.ibamu_consent_content_body',
        el('div.ibamu_consent_tabs',
          cookie_modal_tab_groups()
        )
      ),

      el('div.lawwwing-consent-footer-wrapper',
        powered_by,
        el('div.ibamu_consent_footer',
          el('button#ibamu_consent_footer_consent-modal-submit', translate(config.locales, 'modal_btn_save')),
          el('button.ibamu-consent-accept', translate(config.locales, 'modal_btn_accept')),
          el('button.ibamu-consent-reject', translate(config.locales, 'modal_btn_reject'))
        )
      )
    )
  );
}
