import { mount } from 'redom';

import { flush } from './../block/flush';
import Utils from './../utils';
import { build_style } from './style';
import { build_banner } from './banner';
import { build_modal } from './modal';
import { build_documents_footer, build_document_modal, update_document_modal } from './documents';
import { STORAGE_COOKIE } from './../variables';


function getDlParent(event) {
  const parent = event.parentNode;
  if (parent.nodeName !== 'DL') {
    return getDlParent(parent);
  } else {
    return parent;
  }
}

export default class Interface {

  constructor() {
    this.elements = {};
    this.config = window.Lawwwing.config;
  }

  modal_redraw_icons() {
    const tabGroups = this.elements['cookie-modal'].querySelectorAll('.ibamu_consent_tabgroup');

    for (const tabGroup of tabGroups) {
      if (window.Lawwwing.config.categories[tabGroup.dataset.category].checked) {
        if (!tabGroup.classList.contains('ibamu_checked')) {
          tabGroup.classList.add('ibamu_checked');
          tabGroup.querySelector('input.category-onoff').checked = true;
        }
      } else {
        if (tabGroup.classList.contains('ibamu_checked')) tabGroup.classList.remove('ibamu_checked');
        tabGroup.querySelector('input.category-onoff').checked = false;
      }
    }
  }

  render(name, elem, callback) {
    if (typeof elem === 'undefined') return;
    if (typeof callback === 'undefined') callback = function() {};
    if (typeof this.elements[name] !== 'undefined') {
      this.elements[name].parentNode.replaceChild(elem, this.elements[name]);
      this.elements[name] = elem;
      callback(elem);
      return elem;
    } else {
      const insertedElem = mount(document.body, elem);
      if (insertedElem) {
        this.elements[name] = insertedElem;
      }
      callback(insertedElem);
      return insertedElem;
    }
  }

  build_interface(callback) {
    if (typeof callback === 'undefined') callback = function() {};
    const that = this;

    Utils.ready(function() {
      that.render('style', build_style());

      that.render('bar', build_banner(), (bar) => {
        // Show the bar after a while
        if (!window.Lawwwing.storage) {
          setTimeout(() => {
            bar.classList.remove('lawwwing-banner-hidden');
          }, window.Lawwwing.config.features.timeout);
        }
      });

      // render cookie modal
      that.render('cookie-modal', build_modal());

      // render document footer depending on documents enabled
      that.render('document-footer', build_documents_footer());

      callback();
    });
  }

  close_banner() {
    flush();

    this.buildCookie((cookie) => {
      this.setCookie(cookie);
    });

    this.elements.bar.classList.add('lawwwing-banner-hidden');
    this.elements['cookie-modal'].classList.remove('lawwwing-modal-visible');

    this.modal_redraw_icons();

    // if autoreload is setted, reload page
    if (window.Lawwwing.config.features.autoreload === true) {
      document.location.reload(true);
    }
  }

  add_event_listeners() {
    // If you click Accept only needed cookies
    const button_consent_accepted = document.querySelectorAll('.ibamu-consent-accept');
    for (const button of button_consent_accepted) {
      button.addEventListener('click', () => {

        // Depending on widget operation mode select all cookies or only needed
        for (const key in window.Lawwwing.config.categories) {

          if (this.config.features.mode === 'all') {
            window.Lawwwing.config.categories[key].wanted = window.Lawwwing.config.categories[key].checked = true;
          }

          if (this.config.features.mode === 'needed') {
            if (window.Lawwwing.config.categories[key].needed === true) {
              window.Lawwwing.config.categories[key].wanted =
              window.Lawwwing.config.categories[key].checked = true;
            }
          }
        }
        this.close_banner();
      });
    }

    // If you click reject all cookies
    const button_consent_reject = document.querySelectorAll('.ibamu-consent-reject');
    for (const button of button_consent_reject) {
      button.addEventListener('click', () => {
        for (const key in window.Lawwwing.config.categories) {
          if (window.Lawwwing.config.categories[key].needed === false) {
            window.Lawwwing.config.categories[key].wanted = window.Lawwwing.config.categories[key].checked = false;
          }
        }
        this.close_banner();
      });
    }

    // If you click on a button with ibamu-consent-edit or lawwwing-consent-edit class open cookie-modal
    Array.prototype.forEach.call(document.querySelectorAll('.ibamu-consent-edit,.lawwwing-consent-edit'), (edit) => {
      edit.addEventListener('click', () => {
        this.elements['cookie-modal'].classList.add('lawwwing-modal-visible');
      });
    });

    // If you click Cookie settings -> open modal
    Array.prototype.forEach.call(document.querySelectorAll('.ibamu-banner-edit,.lawwwing-banner-edit'), (edit) => {
      edit.addEventListener('click', () => {
        this.elements['cookie-modal'].classList.add('lawwwing-modal-visible');
      });
    });

    const self = this;
    const config = window.Lawwwing.config;

    // Function to add event listeners on document-modal element
    function add_document_modal_event_listeners(element, document_type) {
      element.addEventListener('click', () => {
        const element_name = `document-modal-${document_type}`;
        // build modal with iframe and open
        self.render(element_name, build_document_modal(document_type));
        self.elements[element_name].classList.add('lawwwing-modal-visible');
        // add listener for close button on document modal
        self.elements[element_name].querySelector('.ibamu-document-close').addEventListener('click', () => {
          self.elements[element_name].classList.remove('lawwwing-modal-visible');
        });
        // add listener for changes on document language selector
        self.elements[element_name].querySelector('select#lawwwing-document-languages').addEventListener('change', (event) => {
          update_document_modal(self.elements[element_name], document_type, event.target.value);
        });
      });
    }

    // For each document add an event listerner for footer buttons and outside buttons
    Object.keys(config.documents).forEach(document_type => {
      Array.prototype.forEach.call(
        document.getElementsByClassName(`ibamu-document-open ${document_type}`),
        (element) => add_document_modal_event_listeners(element, document_type)
      );
      Array.prototype.forEach.call(
        document.getElementsByClassName(`lawwwing-document-open ${document_type}`),
        (element) => add_document_modal_event_listeners(element, document_type)
      );
    });

    // If you click trough the tabs on Cookie settings
    // If you click on/off switch
    this.elements['cookie-modal'].querySelector('.ibamu_consent_tabs').addEventListener('click', (event) => {

      // If you click trough the tabs on Cookie settings
      if (event.target.classList.contains('ibamu_consent_tab-head') || event.target.classList.contains('ibamu_consent_tab-head_icon-wedge')) {
        const parentDl = getDlParent(event.target);
        if (parentDl.classList.contains('ibamu_consent_tabgroup-open')) {
          parentDl.classList.remove('ibamu_consent_tabgroup-open');
        } else {
          parentDl.classList.add('ibamu_consent_tabgroup-open');
        }
      }

      // If you click on/off switch
      if (event.target.classList.contains('category-onoff')) {
        window.Lawwwing.config.categories[event.target.dataset.category].wanted =
        window.Lawwwing.config.categories[event.target.dataset.category].checked = (event.target.checked === true);

        const dt = document.querySelector('.ibamu_consent_tabgroup.' + event.target.dataset.category);
        if (event.target.checked === false && dt.classList.contains('ibamu_checked')) {
          dt.classList.remove('ibamu_checked');
        } else {
          dt.classList.add('ibamu_checked');
        }
      }
    });

    // If you click close on open modal
    this.elements['cookie-modal'].querySelector('.ibamu_consent_cheading_close').addEventListener('click', () => {
      this.elements['cookie-modal'].classList.remove('lawwwing-modal-visible');
    });

    // If you click submit on cookie settings
    document.getElementById('ibamu_consent_footer_consent-modal-submit').addEventListener('click', () => {

      const switchElements = this.elements['cookie-modal'].querySelectorAll('.ibamu_consent_switch input');

      Array.prototype.forEach.call(switchElements, (switchElement) => {
        window.Lawwwing.config.categories[switchElement.dataset.category].wanted = switchElement.checked;
      });

      this.buildCookie((cookie) => {
        this.setCookie(cookie, () => {
          this.elements['cookie-modal'].classList.remove('lawwwing-modal-visible');
          this.elements.bar.classList.add('lawwwing-banner-hidden');
        });
      });

      flush();

    });
  }

  buildCookie(callback) {
    const cookie = {
      version: window.Lawwwing.config.version,
      categories: {},
      services: []
    };

    for (const key in window.Lawwwing.config.categories) {
      cookie.categories[key] = {
        wanted: window.Lawwwing.config.categories[key].wanted
      };
    }

    cookie.services = Utils.listGlobalServices();
    if (callback) callback(cookie);
    return cookie;
  }

  setCookie(cookie, callback) {
    const expires_in = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString();

    document.cookie = `${STORAGE_COOKIE}=${JSON.stringify(cookie)}; expires=${expires_in}; path=/;`;
    if (callback) callback();
  }
}
